import React, { useEffect, memo, useState } from "react";

import SEO from "../components/layout/seo";
import SubHeader from "../components/layout/subheader";

import Space from "../components/space";

import Aos from 'aos';

import { Link } from 'gatsby';




const AccordionItem = ({children, titulo = '', isOpen}) =>
{
    const [open, setOpen] = useState(isOpen);

    /* useEffect(() => {
        console.log('AccordionItem:','render');
        return () => {
            console.log('AccordionItem:','unmount');
        }
    }, []); */

    const openItem = () =>
    {
        setOpen(!open);
    }

    return (
        <div data-aos="fade-in" data-aos-duration="800" className="homeaccordion__item">
            <div className={open === true ? `homeaccordion__item__name` : `homeaccordion__item__name homeaccordion__item__closed`} onClick={openItem} role="presentation">
                <div className="ion android-arrow-dropdown-circle homeaccordion--arrow"></div>
                <h2>{titulo}</h2>
            </div>
            <div className="homeaccordion__item__text">
                {children}
            </div>
        </div>
    );
}



const onFocus = () =>
{
    //console.log('TempPaginaPoliticaDePrivacidade:','onFocus');
    Aos.refresh();
}

const TempPaginaPoliticaDePrivacidade = () =>
{
    useEffect(() =>
    {
        //
        // componentDidMount

        //console.log('TempPaginaPoliticaDePrivacidade:','render');

        if ( typeof Aos !== `undefined` && typeof window !== `undefined` ) {
            setTimeout(function() {
                //Aos.refresh();
                Aos.init();
            }, 500);
            window.addEventListener("focus", onFocus);
        }

        //
        // componentWilUnmount

        return () => {
            //console.log('TempPaginaPoliticaDePrivacidade:','unmount');

            if ( typeof Aos !== `undefined` && typeof window !== `undefined` )
            {
                window.removeEventListener("focus", onFocus);
                //Aos.disable();
            }
        };
    }, []);

    return (
        <>
            <SEO title="Politica de privacidade" />
            <SubHeader pagename="Politica de privacidade" bg="page-bg-politica-de-privacidade.jpg" />

            <div style={{marginBottom: 100}}></div>
            <div className="page__body page__privacy">
                <div className="page__content">

                    <AccordionItem titulo="Sobre a Política de Privacidade" isOpen={false}>
                        Nossa política de privacidade descreve:<br />
                        <ul>
                            <li>Quais dados recolhemos dentro do nosso site: <Link to="/">arinteligenciatributaria.com.br</Link>.</li>
                            <li>Como os dados são processados.</li>
                            <li>Quais os seus direitos.</li>
                            <li>Como entrar em contato conosco.</li>
                        </ul>
                        Política de Privacidade publicada em 29 de Junho de 2020
                    </AccordionItem>
                    <AccordionItem titulo="Quais dados pessoais são coletados e por que?" isOpen={false}>
                        Você pode visitar o <Link to="/">nosso site</Link> sem compartilhar qualquer informação pessoal, mas <b>quando você se inscrever</b> em algum dos nossos formulários, as seguintes informações podem ser solicitadas:<br />
                        <ul>
                            <li>Formulário <Link to="/fale-conosco/">Fale Conosco</Link>: Nome, E-mail e Telefone.</li>
                        </ul>
                    </AccordionItem>
                    <AccordionItem titulo="Dados técnicos (Cookies)" isOpen={false}>
                        <b>O que são Cookies:</b><br />
                        <ul>
                            <li>Cookie é um arquivo de texto contendo um número de identificação exclusiva.<br />Quando você voltar a visitar o <Link to="/">nosso site</Link>, o site irá procurar esse número no seu navegador e saber se você já acessou o site anteriormente.</li>
                        </ul>
                        <b>Cookies processados:</b><br />
                        <ul>
                            <li><b>banners</b>: Guarda um registro dos banners e ajuda no tempo de carregamento da página inicial. Ele expira após fechar a aba do site ou o navegador.</li>
                        </ul>
                        <b>Mais informações:</b><br />
                        <ul>
                            <li><b>Gestão de cookies (Google Chrome)</b>: <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt" target="_blank" rel="noreferrer nofollow">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt</a></li>
                            <li><b>Gestão de cookies (Firefox)</b>: <a href="https://support.mozilla.org/pt-PT/kb/cookies-informacao-que-os-websites-guardam-no-seu-computador" target="_blank" rel="noreferrer nofollow">https://support.mozilla.org/pt-PT/kb/cookies-informacao-que-os-websites-guardam-no-seu-computador</a></li>
                        </ul>
                    </AccordionItem>
                    <AccordionItem titulo="Dados técnicos (Google Analytics)" isOpen={false}>
                        <b>O que é o Google Analytics:</b><br /><ul><li>Uma ferramenta de análise oferecida pelo Google.</li></ul><b>Como funciona?:</b><br /><ul><li>Ele usa Cookies para contabilizar o número de visitantes do <Link to="/">nosso site</Link> por região, páginas acessadas e outras estatísticas de visitação. <a target="_blank" rel="noreferrer" href="https://support.google.com/analytics/answer/7318509?hl=pt-BR">Clique aqui e leia a Política de Privacidade do Google</a>.</li></ul>
                    </AccordionItem>
                    <AccordionItem titulo="Sites de terceiros" isOpen={false}>
                        Dentro do <Link to="/">nosso site</Link> você encontra o endereço de outros sites ou redes sociais. Eles tem uma infraestrutura própria e portanto não fazem parte da nossa <Link to="/politica-de-privacidade/">Política de Privacidade</Link>.
                    </AccordionItem>
                    <AccordionItem titulo="Com quem partilhamos seus dados pessoais?" isOpen={false}>
                        <ul><li><b>Armazenamento:</b> Os dados dos formulários ficam nos servidores da <a href="https://www.hostgator.com.br/politica-de-privacidade" target="_blank" rel="noreferrer nofollow">HostGator</a>.<br /><br /></li><li><b>Encaminhamentos:</b> Os dados dos formulários também são encaminhados automaticamente para os e-mails da A & R Inteligência Tributária e processados pelos departamentos responsáveis.<br /><br /></li><li><b>Processamento:</b> A administração do site e os departamentos responsáveis podem ver, alterar ou apagar os seus dados a qualquer momento.</li></ul>
                    </AccordionItem>
                    <AccordionItem titulo="Por quanto tempo mantemos os seus dados?" isOpen={false}>
                        Se você se inscrever em um dos formulários, o formulário e os seus metadados são conservados indefinidamente. Fazemos isso para manter um registro seguro de todas as <Link to="/fale-conosco/">solicitações de contato</Link> e cadastro.
                    </AccordionItem>
                    <AccordionItem titulo="Quais os seus direitos sobre seus dados?" isOpen={false}>
                        Se você tiver uma conta neste site, pode solicitar um arquivo exportado dos dados pessoais que mantemos sobre você, inclusive quaisquer dados que nos tenha fornecido. Também pode solicitar que removamos qualquer dado pessoal que mantemos sobre você. Isto não inclui nenhuns dados que somos obrigados a manter para propósitos administrativos, legais ou de segurança.
                    </AccordionItem>
                    <AccordionItem titulo="Contato" isOpen={false}>
                        <Link to="/fale-conosco/">Mande-nos uma mensagem</Link>, ou se preferir, ligue-nos no <span>(18) 3216-2987</span> ou <Link to="/fale-conosco/#mapa">faça-nos uma visita</Link>.
                    </AccordionItem>

                    <Space altura="20" />

                    <p className="link" data-aos="fade-in" data-aos-duration="1000"><Link to="/termos-e-condicoes/">Veja também nossos termos e condições</Link></p>
                </div>
            </div>
            <div style={{marginBottom: 100}}></div>
        </>
    )
}

const PaginaPoliticaDePrivacidade = memo(TempPaginaPoliticaDePrivacidade);

export default PaginaPoliticaDePrivacidade;